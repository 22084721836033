
import FaceCamera from "./FaceCamera";
import FaceUi from "./FaceUi";


function FaceAutoCapture({ onPhotoTaken, onError }) {


    const handlePhotoTaken = async (imageData, content) => {
        onPhotoTaken(imageData, content);
    };

    return <> <FaceCamera cameraFacing="user" onPhotoTaken={handlePhotoTaken} onError={onError} />
        <FaceUi showCameraButtons
            instructions={{
                candidate_selection: "Mantén el rostro en posición",
                face_too_close: "Aleja el rostro",
                face_too_far: "Acerca el rostro",
                face_centering: "Centra el rostro",
                face_not_present: "Posiciona el rostro dentro del circulo",
                left_eye_not_present: "Posiciona el rostro dentro del circulo",
                right_eye_not_present: "Posiciona el rostro dentro del circulo",
                mouth_not_present: "Posiciona el rostro dentro del circulo",
                sharpness_too_low: "Se necesita más luz",
                brightness_too_low: "Se necesita más luz",
                brightness_too_high: "Se necesita menos luz",
                device_tilted: "Mantén tu teléfono recto a nivel de los ojos",
                device_pitched: "Mantén tu teléfono recto a nivel de los ojos",

            }}
            appStateInstructions={{ loading: { text: "Cargando...", visible: true } }} />
    </>
}

export default FaceAutoCapture;
