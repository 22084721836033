import axios from "axios";
import { AutenticApi } from "../Config";
import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";

const AutenticServices = () => {

    const { getStorage } = useCreditRequestAuth();
    let creditRequestToken = getStorage("creditRequestToken")



    const PrepareAndNotify = async (IdApp) => {

        let respose = await axios.get(AutenticApi("Contracts/PrepareAndNotify/" + IdApp),
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const OTPAndSignContract = async (body) => {

        let respose = await axios.post(AutenticApi("Contracts/OTPAndSignContract"),
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const ResendOTP = async (body) => {

        let respose = await axios.post(AutenticApi("Contracts/ResendOTP"),
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };


    return {
        PrepareAndNotify, OTPAndSignContract,ResendOTP
    }
}

export default AutenticServices;