import axios from "axios";
import { GogolApi } from "../Config";
import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";

const GogolServices = () => {

    const { getStorage } = useCreditRequestAuth();
    let creditRequestToken = getStorage("creditRequestToken")



    const GeneratePdfOlimpiaById = async (Template, IdApp) => {

        let respose = await axios.get(GogolApi("GenerateDocumentPdf/GeneratePdfOlimpiaById?Template=" + Template + "&IdApp=" + IdApp + "&GenerateFile=false"),
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const GetPDFforAutentic = async (Template, IdApp) => {

        let respose = await axios.get(GogolApi("GenerateDocumentPdf/GetPDFforAutentic?Template=" + Template + "&IdApp=" + IdApp + "&GenerateFile=false"),
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    return { GeneratePdfOlimpiaById,GetPDFforAutentic }
}

export default GogolServices;