import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useForm from '../Hooks/useForm';
import BellServices from "../Services/BellServices";
import '../StyleSheets/RequestStepThree.css'
import imgLoader from '../Images/MeddipayLoaderEllipsis.gif'
import { useCreditRequest } from '../Context/useCreditRequest';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";
import AuthSoraService from '../Services/AuthSoraService';
import TarsServices from '../Services/TarsServices';

const validateForm = (form) => {

    let errors = {};

    if (!form.digit1.trim()) { errors.digit1 = "El campo digit1 es requerido" }
    if (!form.digit2.trim()) { errors.digit2 = "El campo digit2 es requerido" }
    if (!form.digit3.trim()) { errors.digit3 = "El campo digit3 es requerido" }
    if (!form.digit4.trim()) { errors.digit4 = "El campo digit4 es requerido" }
    if (!form.digit5.trim()) { errors.digit5 = "El campo digit5 es requerido" }
    if (!form.digit6.trim()) { errors.digit6 = "El campo digit6 es requerido" }

    return errors;

};

const RequestStepThree = () => {

    const { getStorage, setStorage } = useCreditRequestAuth();

    let request = getStorage("request")
    const navigate = useNavigate();
    const initialForm = { digit1: "", digit2: "", digit3: "", digit4: "", digit5: "", digit6: "" };
    const [sending, setSending] = useState(false);
    const { form, errors, handleChangeOtp, handleSubmit, resetForm } = useForm(initialForm, validateForm);
    const { setLogoSize, resendTimeSMS, setResendTimeSMS, resendTimeEmail, setResendTimeEmail, resendTimeWA, setResendTimeWA } = useCreditRequest();
    const formRef = useRef(null);
    const inputReference = useRef(null);
    const [txtHeader, setTxtHeader] = useState("");
    // const [isEmailOTP, setIsEmailOTP] = useState(request.OTP === 1);

    const { GenerateOTPAndSendMessage, GenerateOTPAndSendMessageEmail, ValidateOTP, ValidateEmailOTP, GenerateOTPAndSendMessageWhatsApp, ValidateWhatsAppOTP } = BellServices();
    const { InfolaftConsult } = TarsServices();
    const MySwal = withReactContent(Swal);
    const { GenerateOTPMethod, validateOTPMethod } = AuthSoraService();

    const handlePasteAnywhere = event => {
        let text = event.clipboardData.getData('text');

        if (text.length === 6 && !isNaN(text)) {

            handleChangeOtp({ target: { name: "digit1", value: text[0] } })
            handleChangeOtp({ target: { name: "digit2", value: text[1] } })
            handleChangeOtp({ target: { name: "digit3", value: text[2] } })
            handleChangeOtp({ target: { name: "digit4", value: text[3] } })
            handleChangeOtp({ target: { name: "digit5", value: text[4] } })
            handleChangeOtp({ target: { name: "digit6", value: text[5] } })
        }

    };

    // const handleKeyUp = (e) => {
    //     console.log(e.target.value)
    //     if (e.key === "Backspace") {


    //         let prevIndex = Number(e.target.name.slice(5, 6)) - 1;

    //         if (prevIndex >= 1) {
    //             let nextfield = document.querySelector(
    //                 `input[name=digit${prevIndex}]`
    //             );
    //             nextfield.focus();

    //         }



    //     }
    // }

    useEffect(() => {
        setLogoSize({ width: "240px", marginTop: "40px", marginBottom: "30px" })

        inputReference.current.focus();

        window.addEventListener('paste', handlePasteAnywhere);

        if (request.origin === 1) {
            if (request.OTP === 1) {
                setTxtHeader(`Le hemos enviado un mensaje al correo <b>${request.cusEmail}</b> que contiene un código numérico de 6 digitos, por favor, introduzcalo a continuación.`)
                emailOTP();
            }
            else if (request.OTP === 2) {
                setTxtHeader(`Le hemos enviado un mensaje de texto al número <b>${request.phoneCodeTemp + " " + request.cusPhone}</b> que contiene un código numérico de 6 digitos, por favor, introduzcalo a continuación.`)
                smsOTP();
            }
            else if (request.OTP === 3) {
                setTxtHeader(`Le hemos enviado un mensaje de WhatsApp al número <b>${request.phoneCodeTemp + " " + request.cusPhone}</b> que contiene un código numérico de 6 digitos, por favor, introduzcalo a continuación.`)
                waOTP();
            }
        }
        else {
            if (request.OTP === 1) {
                setTxtHeader(`Le hemos enviado un mensaje al correo <b>${request.cusEmail}</b> que contiene un código numérico de 6 digitos, por favor, introduzcalo a continuación.`)

                OTPMethodEmail();
            }
            else if (request.OTP === 2) {
                setTxtHeader(`Le hemos enviado un mensaje de texto al número <b>${request.cusPhone}</b> que contiene un código numérico de 6 digitos, por favor, introduzcalo a continuación.`)

                OTPMethodSMS();
            }
            else if (request.OTP === 3) {
                setTxtHeader(`Le hemos enviado un mensaje de WhatsApp al número <b>${request.cusPhone}</b> que contiene un código numérico de 6 digitos, por favor, introduzcalo a continuación.`)
                OTPMethodWA();
            }
        }



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => () => {
        window.removeEventListener('paste', handlePasteAnywhere);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const smsOTP = () => {

        if (resendTimeSMS > 0) {

            return;
        }

        setSending(true);

        let body =
        {
            destination: request.phoneCodeTemp + request.cusPhone,
            region: "co-co",
            lang: "co"
        }

        GenerateOTPAndSendMessage(body).then(res => {

            setSending(false);
            if (res.status !== 200) {
                MySwal.fire({
                    html: "Se generó un error al enviar el OTP",
                    icon: 'error'
                })
                return;
            }
            // setIsEmailOTP(false);

            setResendTimeSMS(60);

            var timer = setInterval(() => {

                setResendTimeSMS(t => {
                    const modifiedValue = t - 1;
                    if (modifiedValue <= 0) {
                        clearInterval(timer);
                        setResendTimeSMS(null);
                    }
                    return modifiedValue;
                });


            }, 1000);


        });
    }

    const emailOTP = () => {

        if (resendTimeEmail > 0) {

            return;
        }

        setSending(true);
        let body =
        {
            destination: request.cusEmail,
            region: "co-co",
            lang: "co"
        }

        GenerateOTPAndSendMessageEmail(body).then(res => {

            setSending(false);
            if (res.status === 200) {

                //setTxtHeader(`Le hemos enviado un mensaje al correo <b>${request.cusEmail}</b> que contiene un código numérico de 6 digitos, por favor, introduzcalo a continuación.`)
                // setIsEmailOTP(true);

                setResendTimeEmail(60);

                var timer = setInterval(() => {

                    setResendTimeEmail(t => {
                        const modifiedValue = t - 1;
                        if (modifiedValue <= 0) {
                            clearInterval(timer);
                            setResendTimeEmail(null);
                        }
                        return modifiedValue;
                    });


                }, 1000);

            }
            else {
                MySwal.fire({
                    html: "Se generó un error al enviar el OTP",
                    icon: 'error'
                })
            }

        })
    }

    const waOTP = () => {

        if (resendTimeWA > 0) {

            return;
        }

        setSending(true);

        let body =
        {
            destination: request.phoneCodeTemp + request.cusPhone,
            region: "co-co",
            lang: "co"
        }

        GenerateOTPAndSendMessageWhatsApp(body).then(res => {

            setSending(false);
            if (res.status !== 200) {
                MySwal.fire({
                    html: "Se generó un error al enviar el OTP",
                    icon: 'error'
                })
                return;
            }
            // setIsEmailOTP(false);

            setResendTimeWA(60);

            var timer = setInterval(() => {

                setResendTimeWA(t => {
                    const modifiedValue = t - 1;
                    if (modifiedValue <= 0) {
                        clearInterval(timer);
                        setResendTimeWA(null);
                    }
                    return modifiedValue;
                });


            }, 1000);


        });
    }

    const OTPMethodEmail = () => {

        if (resendTimeEmail > 0) {

            return;
        }

        setSending(true);


        GenerateOTPMethod(request.idMethodEmail).then(res => {

            setSending(false);
            if (res.status === 200) {

                //setTxtHeader(`Le hemos enviado un mensaje al correo <b>${request.cusEmail}</b> que contiene un código numérico de 6 digitos, por favor, introduzcalo a continuación.`)
                // setIsEmailOTP(true);

                setResendTimeEmail(60);

                var timer = setInterval(() => {

                    setResendTimeEmail(t => {
                        const modifiedValue = t - 1;
                        if (modifiedValue <= 0) {
                            clearInterval(timer);
                            setResendTimeEmail(null);
                        }
                        return modifiedValue;
                    });


                }, 1000);

            }
            else {
                MySwal.fire({
                    html: "Error al enviar Codigo al correo",
                    icon: 'error'
                })
            }

        })
    }

    const OTPMethodSMS = () => {

        if (resendTimeSMS > 0) {

            return;
        }

        setSending(true);


        GenerateOTPMethod(request.idMethodSMS).then(res => {

            setSending(false);
            if (res.status === 200) {

                //setTxtHeader(`Le hemos enviado un mensaje al correo <b>${request.cusEmail}</b> que contiene un código numérico de 6 digitos, por favor, introduzcalo a continuación.`)
                //setIsEmailOTP(false);

                setResendTimeSMS(60);

                var timer = setInterval(() => {

                    setResendTimeSMS(t => {
                        const modifiedValue = t - 1;
                        if (modifiedValue <= 0) {
                            clearInterval(timer);
                            setResendTimeSMS(null);
                        }
                        return modifiedValue;
                    });


                }, 1000);

            }
            else {
                MySwal.fire({
                    html: "Error al enviar Codigo al SMS",
                    icon: 'error'
                })
            }

        })
    }

    const OTPMethodWA = () => {

        if (resendTimeWA > 0) {

            return;
        }

        setSending(true);


        GenerateOTPMethod(request.idMethodWA).then(res => {

            setSending(false);
            if (res.status === 200) {

                //setTxtHeader(`Le hemos enviado un mensaje al correo <b>${request.cusEmail}</b> que contiene un código numérico de 6 digitos, por favor, introduzcalo a continuación.`)
                //setIsEmailOTP(false);

                setResendTimeWA(60);

                var timer = setInterval(() => {

                    setResendTimeWA(t => {
                        const modifiedValue = t - 1;
                        if (modifiedValue <= 0) {
                            clearInterval(timer);
                            setResendTimeWA(null);
                        }
                        return modifiedValue;
                    });


                }, 1000);

            }
            else {
                MySwal.fire({
                    html: "Error al enviar Codigo al Whastapp",
                    icon: 'error'
                })
            }

        })
    }

    useEffect(() => {
        let OTP = form.digit1 + form.digit2 + form.digit3 + form.digit4 + form.digit5 + form.digit6;

        if (OTP.length === 6) {
            formRef.current.click();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.digit6]);

    const submitForm = (form, error) => {

        if (!error) {
            setSending(true);

            let OTP = form.digit1 + form.digit2 + form.digit3 + form.digit4 + form.digit5 + form.digit6;

            // if (OTP === "111111") {

            //     setStorage("request", {
            //         ...request,
            //         phoneValidated: true,
            //         state: 4
            //     });

            //     navigate("/Profile");

            //     return;
            // }

            if (request.origin === 1) {
                if (request.OTP === 1) {
                    ValidateEmailOTP(request.cusEmail, OTP, "co-co", "co").then(res => {

                        setSending(false);
                        if (res.status !== 200) {

                            resetForm(initialForm);
                            inputReference.current.focus();
                            MySwal.fire({
                                html: res.data.message,
                                icon: 'error'
                            })
                            return;
                        }

                        const fullName = 
                            request.cusFirstName + 
                            (request.cusSecondName !== "" ? " " + request.cusSecondName : "") +
                            (request.cusFirstSurname !== "" ? " " + request.cusFirstSurname : "") +
                            (request.cusSecondSurname !== "" ? " " + request.cusSecondSurname : "");

                        InfolaftConsult(request.cusDocument, fullName).then(res => { })

                        setStorage("request", {
                            ...request,
                            phoneValidated: true,
                            state: 4
                        });

                        navigate("/Profile");
                    });
                }
                else if (request.OTP === 2) {
                    ValidateOTP(request.phoneCodeTemp + request.cusPhone, OTP, "co-co", "co").then(res => {

                        setSending(false);
                        if (res.status !== 200) {
                            resetForm(initialForm);
                            inputReference.current.focus();
                            MySwal.fire({
                                html: res.data.message,
                                icon: 'error'
                            })

                            return;
                        }

                        const fullName = 
                            request.cusFirstName + 
                            (request.cusSecondName !== "" ? " " + request.cusSecondName : "") +
                            (request.cusFirstSurname !== "" ? " " + request.cusFirstSurname : "") +
                            (request.cusSecondSurname !== "" ? " " + request.cusSecondSurname : "");

                        InfolaftConsult(request.cusDocument, fullName).then(res => { })

                        setStorage("request", {
                            ...request,
                            phoneValidated: true,
                            state: 4
                        });

                        navigate("/Profile");
                    });
                }

                else if (request.OTP === 3) {
                    ValidateWhatsAppOTP(request.phoneCodeTemp + request.cusPhone, OTP, "co-co", "co").then(res => {

                        setSending(false);
                        if (res.status !== 200) {
                            resetForm(initialForm);
                            inputReference.current.focus();
                            MySwal.fire({
                                html: res.data.message,
                                icon: 'error'
                            })

                            return;
                        }                        

                        const fullName = 
                            request.cusFirstName + 
                            (request.cusSecondName !== "" ? " " + request.cusSecondName : "") +
                            (request.cusFirstSurname !== "" ? " " + request.cusFirstSurname : "") +
                            (request.cusSecondSurname !== "" ? " " + request.cusSecondSurname : "");

                        InfolaftConsult(request.cusDocument, fullName).then(res => { })

                        setStorage("request", {
                            ...request,
                            phoneValidated: true,
                            state: 4
                        });

                        navigate("/Profile");
                    });
                }
            }
            else {
                validateOTPMethod(request.OTP === 1 ? request.idMethodEmail : request.OTP === 2 ? request.idMethodSMS : request.idMethodWA, OTP).then(res => {
                    setSending(false);
                    // console.log(res.data)
                    if (res.status !== 200) {
                        resetForm(initialForm);
                        inputReference.current.focus();
                        MySwal.fire({
                            html: res.data.message,
                            icon: 'error'
                        })
                        return;
                    }

                    setStorage("creditRequestToken", JSON.parse(res.data.data).Token, { path: "/" })
                    setStorage("expiredTime", Date.parse(JSON.parse(res.data.data).ExpiredTime), { path: "/" })


                    setStorage("request", {
                        ...request,
                        phoneValidated: true,
                        state: 9
                    });

                    navigate("/Dashboard");
                })
            }


        }


    }

    return (
        <>
            <form onSubmit={(e) => handleSubmit(e, submitForm)} >

                <table id="step-three-table">
                    <tbody>
                        <tr>
                            <td style={{ textAlign: "center", color: "black", fontSize: "13px", padding: "0px" }} colSpan={6}>
                                {request.cusFirstName} {request.cusSecondName} {request.cusFirstSurname} {request.cusSecondSurname}

                            </td>

                        </tr>
                        <tr>
                            <td style={{ textAlign: "center", color: "gray", fontSize: "13px" }} colSpan={6}>{request.cusDocumentTypeTemp}   {request.cusDocument}</td>

                        </tr>

                        {request.origin === 2 ? (<tr><td><br /></td></tr>) : (
                            <tr>
                                <td style={{ textAlign: "center", color: "gray", paddingTop: "15px" }} colSpan={6}>
                                    <h3><b style={{ color: "black" }}>2</b>/4</h3>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td colSpan={6}>
                                <span style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: txtHeader }}></span>
                            </td>
                        </tr>
                        <tr>
                            <td><p></p></td>
                        </tr>
                        <tr>
                            <td>
                                <input ref={inputReference} className={`form-control input-otp ${errors.digit1 && "is-invalid"}`} pattern="[0-9]*" name="digit1" type="number" value={form.digit1} onChange={handleChangeOtp} />
                            </td>
                            <td>
                                <input className={`form-control input-otp ${errors.digit2 && "is-invalid"}`} pattern="[0-9]*" name="digit2" type="number" value={form.digit2} onChange={handleChangeOtp} />
                            </td>
                            <td>
                                <input className={`form-control input-otp ${errors.digit3 && "is-invalid"}`} pattern="[0-9]*" name="digit3" type="number" value={form.digit3} onChange={handleChangeOtp} />
                            </td>
                            <td>
                                <input className={`form-control input-otp ${errors.digit4 && "is-invalid"}`} pattern="[0-9]*" name="digit4" type="number" value={form.digit4} onChange={handleChangeOtp} />
                            </td>
                            <td>
                                <input className={`form-control input-otp ${errors.digit5 && "is-invalid"}`} pattern="[0-9]*" name="digit5" type="number" value={form.digit5} onChange={handleChangeOtp} />
                            </td>
                            <td>
                                <input className={`form-control input-otp ${errors.digit6 && "is-invalid"}`} pattern="[0-9]*" name="digit6" type="number" value={form.digit6} onChange={handleChangeOtp} />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={6}><p></p></td>
                        </tr>

                        <tr>
                            <td colSpan={6}><p></p></td>
                        </tr>
                        <tr>
                            <td colSpan={6} style={{ color: (request.OTP === 1 ? resendTimeEmail : request.OTP === 2 ? resendTimeSMS : resendTimeWA) ? "gray" : "blue", cursor: (request.OTP === 1 ? resendTimeEmail : request.OTP === 2 ? resendTimeSMS : resendTimeWA) ? "not-allowed" : "pointer" }}>
                                <u onClick={!(request.OTP === 1 ? resendTimeEmail : request.OTP === 2 ? resendTimeSMS : resendTimeEmail) ? (request.origin === 1 ? (request.OTP === 1 ? emailOTP : request.OTP === 2 ? smsOTP : waOTP) : (request.OTP === 1 ? OTPMethodEmail : request.OTP === 2 ? OTPMethodSMS : OTPMethodWA)) : undefined}>{request.OTP === 1 ? "Reenviar código al correo" : request.OTP === 2 ? "Reenviar código al celular" : "Reenviar código al WhatsApp"}</u>
                            </td>

                        </tr>
                        <tr>
                            <td colSpan={6}>{request.OTP === 1 ? resendTimeEmail : request.OTP === 2 ? resendTimeSMS : resendTimeWA}</td>
                        </tr>
                        <tr>
                            <td colSpan={6}><p></p></td>
                        </tr>
                        {/* <tr>
                            <td colSpan={6} style={{ color: resendTime ? "gray" : "blue", cursor: resendTime ? "not-allowed" : "pointer" }}>
                                <u onClick={!resendTime ? (isEmailOTP ? smsOTP : emailOTP) : undefined}>{isEmailOTP ? "Realizar verificación mediante celular" : "Realizar verificación mediante correo electronico"}</u>
                            </td>
                        </tr> */}
                        <tr>
                            <td colSpan={6}><p></p></td>
                        </tr>
                        <tr>
                            <td colSpan={6}><p></p></td>
                        </tr>
                        <tr>
                            <td colSpan={6}>
                                <Link to="/DigitalConfirmation" onClick={() => {
                                    setStorage("request", {
                                        ...request, state: 2
                                    })
                                }}>
                                    <button type="button" disabled={sending} className="btn btn-mp-back" style={{ marginRight: "20px" }}>Regresar</button>
                                </Link>
                                <input type="submit" value="Continuar" disabled={sending} className="btn btn-mp" ref={formRef} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {sending &&
                                    (
                                        <div className="my-loader">
                                            <div className="my-loader-container">
                                                <img src={imgLoader} alt="loader"></img>

                                            </div>
                                        </div>
                                    )}
                            </td>
                        </tr>
                    </tbody>
                </table>

            </form>



        </>

    );
}

export default RequestStepThree;